import { useAuth } from '@/services/Auth'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import React, {useEffect, useRef, useState} from 'react'
import { Helmet } from 'react-helmet'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import { useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import LoadingButton from '@/shared/components/LoadingButton'
import Table, { useColumns } from '@/shared/Tables/Top15Deals'
import LOCATION from '@/constants/Location'
import LocationSearch from "../Deals/components/LocationSearch";
import FullDealCard from "../../Deals/components/FullDealCard";
import {deleteRequest} from "../../../services/Api";

function DestinationSpecificDeals() {
  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()
  const [query, setQuery] = useState(route.state && route.state.query || { page: 0, skip: 0, limit: 15, latest: 1 })

  const request = useRef(null);

  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deals, setDeals] = useState([]);

  const {
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...query
    }
  })

  const setQueryData = (values) => {
    setQuery(values)
  }

  const preserveDeal = (deal) => {
    auth.postRequest(LOCATION.DEALS.API.PRESERVE(deal.id))
        .then(response => {
            deal.preserve = !deal.preserve
            setDeals([...deals])
        })
        .catch(error => {
        })
  }

  const removeDeal = (deal) => {
    auth.deleteRequest(LOCATION.DEALS.API.DELETE(deal.id))
        .then(response => {
            setDeals(deals.filter(_ => _.id !== deal.id))
        })
        .catch(error => {
        })
  }

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,
        query,
      }
    );

    if (query.location) {
        setSearching(true)
        auth.getRequest(LOCATION.DEALS.API.path, {
            location_id: query.location,
            deal_type_id: 7,
            sort_by: 'cheapest_first'
        })
            .then(response => {
              setDeals(response.data.data)
              setSearching(false)
            })
            .catch(error => {
              setSearching(false)
            })
    }
  }, [query])

  return (
    <>
      <div className="py-3 px-4">
        <div className="d-flex justify-content-between">

          <AdminPageHeader
            mainTitle={t('pages.destination_specific_deals.title')}
          />
          <div className="d-flex">
            {/*<Link to={`${LOCATION.DEALS.LIST.path}/search`} className="btn btn-dark mx-2 heading-text">*/}
            {/*  {t('pages.top_15_deals.buttons.search_for_deals')}*/}
            {/*</Link>*/}
            {/*<Link className="btn btn-primary heading-text" to={`${LOCATION.DEALS_WEEK.LIST.path}/create`}>*/}
            {/*  {t('pages.top_15_deals.buttons.create_deal_week')}*/}
            {/*</Link>*/}
          </div>
        </div>
        <Helmet>
          <title>{t('pages.destination_specific_deals.title')} - {t('common.app')}</title>
        </Helmet>

        <div className="mt-3">
          <Row>
            <Form.Group className="col col-sm-12 col-md-6 col-12 mb-2 mb-md-1">
              <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                {t('pages.locations.location')}
                <span className="text-danger ms-2 small">*</span>
              </Form.Label>
              <LocationSearch
                  setValue={(name, value) => setQueryData({ ...query, [name]: value })}
                  location={query.location}
                  fieldName="location"
              />
            </Form.Group>
          </Row>
        </div>

          <Row>
              {
                  deals?.map(deal => {
                      return (
                          <Col className="col-lg-4 col-md-6 col-12 mb-4" key={deal.id}>
                              <FullDealCard
                                  deal={deal}
                                  hideDescription={true}
                                  linkToEditDeal={true}
                                  preserveDeal={preserveDeal}
                                  removeDeal={removeDeal}
                                  />
                          </Col>
                      )
                  })
              }
        </Row>
      </div>
    </>
  )
}
export default DestinationSpecificDeals;