import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import { loadStripe } from '@stripe/stripe-js'
import PageHeader from '@/layouts/components/PageHeader'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRollbar } from '@rollbar/react'
import AppModal from '@/shared/components/AppModal'
import { Link, useParams } from 'react-router-dom'
import SubscriptionForm from './Forms/SubscriptionForm'
import PaymentMethodForm from './Forms/PaymentMethodForm'
import ConfirmPayment from './components/ConfirmPayment'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

function Payments (props) {
  const auth = useAuth()
  const { t } = useTranslation()

  // const rollbar = useRollbar()

  const { source, trial } = useParams()

  const stripeSetupFormRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const [claimedTrial, setClaimedTrial] = useState(false)
  const [confirmPayment, setConfirmPayment] = useState(false)
  const [paymentIntentData, setPaymentIntentData] = useState(false)
  const [activeMember, setActiveMember] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [body, setModalBody] = useState(null)
  const [subscriptionDuration, setSubscriptionDuration] = useState('year')
  const [requestValues, setRequestValues] = useState(null)
  const [confirmText, setConfirmText] = useState(t('common.okay'))
  const [cancelText, setCancelText] = useState(null)
  const [promoValid, setPromoValid] = useState(null)
  const [promoCodeModel, setPromoCodeModel] = useState(null)
  const [promoMessage, setPromoMessage] = useState(null)


  const [processingPaypal, setProcessingPaypal] = useState(false)

  const [serverError, setServerError] = useState(null)

  const schema = Yup.object().shape({})

  const {
    handleSubmit: processSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    setError
  } = useForm({
    resolver: yupResolver(schema),
  });

  const promoCode = watch('promo_code');

  useEffect(() => {
    if (trial === '1') {
      // Check with api trial is allowed
      auth.getRequest(`${LOCATION.SUBSCRIPTION_PRODUCTS.API.path}`, {
        allow_trial: true
      }).then(response => {
        if (response.data.length === 0) {
          window.location.href = `/payments/create/${source}/0`;
        }
      });
    }
  }, [])

  const buyTripPlan = () => {
    setLoading(true)
    auth.postRequest(`${LOCATION.TRANSACTIONS.API.path}`, {
      description: 'Trip Plans',
      user_id: auth.user.id,
      type: 'trip_plan',
    })
      .then(response => {
        setModalTitle(t('pages.payments.notifications.trip_play_purchased.title'))
        setModalBody(t('pages.payments.notifications.trip_play_purchased.body'))
        setLoading(false)
        setShowModal(true)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const checkoutWithPaypal = () => {
    if (promoValid === false) {
      setServerError('promo_code_invalid');
      return;
    }
    setServerError(null)
    setProcessingPaypal(true)
    auth.postRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/subscribe`, {
      interval: subscriptionDuration,
      trial,
      type: 'paypal',
      promo_code: promoCode,
    })
      .then(response => {

        let approvalLink = response.data?.links.find(_ => _.rel === 'approve');
        if( approvalLink ){
          window.location.assign(approvalLink.href);
        }
        else{
          setModalTitle(t('pages.payments.notifications.subscription_error.title'))
          setModalBody(t('pages.payments.notifications.subscription_error.body'))
          setShowModal(true)
        }

        setProcessingPaypal(false)
      })
      .catch(error => {
        setServerError(error.response.data.message)
        setProcessingPaypal(false)
      })
  }

  const subscribe = () => {
    if (promoValid === false) {
      setServerError('promo_code_invalid');
      return;
    }
    setServerError(null)
    setLoading(true)
    auth.postRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/subscribe`, {
      interval: subscriptionDuration,
      trial,
      type: 'stripe',
      promo_code: promoCode,
    })
      .then(response => {
        setModalTitle(t('common.notifications.subscribed.title'))
        setModalBody(t('common.notifications.subscribed.body'))
        setLoading(true)
        setShowModal(true)
        auth.checkToken()
      })
      .catch(error => {
        if (error.response.status === 422) {
          if (error.response.data.message === '3d_secure_process') {
            setConfirmPayment(true)
            setPaymentIntentData(error.response.data)
          } else {
            setLoading(false)
            setServerError(error.response.data.message)
          }
        }
      })

  }

  const subscriptionConfirmed = () => {
    setLoading(false)
    setModalTitle(t('common.notifications.subscribed.title'))
    setModalBody(t('common.notifications.subscribed.body'))
    setShowModal(true)
  }

  const savePaymentMethod = useCallback((values) => {
    if (promoValid === false) {
      setServerError('promo_code_invalid');
      return;
    }

    setServerError(null)
    setLoading(true)
    values.interval = subscriptionDuration

    if (stripeSetupFormRef.current)
      stripeSetupFormRef.current(values)
        .then((payload) => {
          auth.postRequest(`${LOCATION.USERS.API.path}/${auth.user.id}/payment-method`, {
            source: source,
            trial: trial,
            promo_code: promoCode,
            ...payload
          })
            .then(response => {
              auth.checkToken()

              setLoading(false)
              if (source === 'subscribe') {
                setModalTitle(t('common.notifications.subscribed.title'))
                setModalBody(t('common.notifications.subscribed.body'))
              } else if (source === '/buy-trip') {
                setModalTitle(t('pages.payments.notifications.trip_play_purchased.title'))
                setModalBody(t('pages.payments.notifications.trip_play_purchased.body'))
              } else {
                setModalTitle(t('pages.payments.notifications.payment_method_saved.title'))
                setModalBody(null)
              }
              setShowModal(true)

            })
            .catch(error => {
              if (error.response.status === 422) {
                if (error.response.data.message === '3d_secure_process') {
                  setConfirmPayment(true)
                  setPaymentIntentData(error.response.data)
                } else {
                  setLoading(false)
                  setServerError(error.response.data.message)
                }
              }
            })
        })
        .catch((error) => {

          if (error.response.status === 422) {
            setServerError(error.response.data.message)
          }

          setLoading(false)
          switch (error?.error?.code) {
            case 'incomplete_number':
              setError('card_number', { message: t('common.form_validation.is_required', { attribute: t('pages.payments.form.card_number') }) })
              break

            case 'invalid_number':
              setError('card_number', { message: t('common.form_validation.invalid_card_number') })
              break

            case 'incomplete_expiry':
              setError('expire', { message: t('common.form_validation.is_required', { attribute: t('pages.payments.form.expire') }) })
              break

            case 'invalid_expiry_month_past':
            case 'invalid_expiry_year_past':
            case 'invalid_expiry':
              setError('expire', { message: t('common.form_validation.invalid_expire') })
              break

            case 'incomplete_cvc':
              setError('ccv', { message: t('common.form_validation.is_required', { attribute: t('pages.payments.form.ccv') }) })
              break

            case 'invalid_cvc':
              setError('ccv', { message: t('common.form_validation.invalid_ccv') })
              break

            default:
              console.error(error)
              // rollbar.error(error)
          }
        })
  }, [stripeSetupFormRef.current])

  const handleConfirm = () => {
    setShowModal(false)
    if (activeMember) {
      if (props.history?.goBack) {
        props.history.goBack()
      } else {
        window.location.href = '/'
      }
      setActiveMember(false)
    } else if (requestValues) {
      window.location.href = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/summary`
    } else if (source === 'buy-trip') {
      props.history.push(`${LOCATION.TRIPS.REQUEST_A_TRIP.path}/1`)
    } else if (claimedTrial) {

      setClaimedTrial(false)
      setCancelText(null)
      props.history.push(`${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/0`)
    } else {
      props.history.push(`${LOCATION.PROFILE.EDIT.path}`)
    }
  }

  const handleCancel = () => {
    props.history.push(`${LOCATION.PROFILE.EDIT.path}`)
  }

  useEffect(() => {
    if (source === 'subscribe' && auth.user.travel_max_user) {
      setModalTitle(t('pages.payments.notifications.you_have_active_subscription.title'))
      setModalBody(t('pages.payments.notifications.you_have_active_subscription.body'))
      setShowModal(true)
      setActiveMember(true)
    } else if (trial === '1' && auth.user.claimed_subscription_trial) {
      setModalTitle(t('pages.payments.notifications.you_have_claimed_subscription_trial.title'))
      setModalBody(t('pages.payments.notifications.you_have_claimed_subscription_trial.body'))
      setConfirmText(t('pages.payments.notifications.you_have_claimed_subscription_trial.buttons.subscribe_for_full_plan'))
      setCancelText(t('common.buttons.cancel'))
      setShowModal(true)
      setClaimedTrial(true)
    }
  }, [])

  useEffect(() => {
    register('country_id')
    register('card_number')
    register('expire')
    register('ccv')
  }, [])

  useEffect(() => {
    let requestData = localStorage.getItem('request_data')
    if (requestData) {
      setRequestValues(JSON.parse(requestData))
    }

  }, [])

  useEffect(() => {
    let timeout;

    // Check if promo code is valid after a delay
    timeout = setTimeout(() => {
      if (promoCode.length > 3) {
        auth.postRequest(`/subscription-product-promotions/validate`, {
          promo_code: String(promoCode).trim().toUpperCase()
        })
            .then(response => {
              setPromoValid(!!response.data?.result);
              if (response.data?.result) {
                setServerError(null);
                setPromoCodeModel(response.data?.promotion);
                setPromoMessage(`Promo code applied. £${response.data?.promotion?.price} for ${response.data?.promotion?.frequency} ${response.data?.promotion?.frequency > 1 ? response.data?.promotion?.interval + 's' : response.data?.promotion?.interval}`);
              } else {
                setServerError('promo_code_invalid');
                setPromoCodeModel(null);
                setPromoMessage(null);
              }
            })
            .catch(() => {
              setPromoValid(false);
              setServerError('promo_code_invalid');
              setPromoCodeModel(null);
              setPromoMessage(null);
            });
      }

      if (promoCode === '' || promoCode === undefined || promoCode === null) {
        setPromoValid(null);
      }
    }, 1500);

    // Clear timeout when unmounting or before the next effect runs
    return () => clearTimeout(timeout);
  }, [promoCode]);

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={body}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />

      <PageHeader
        title={source === 'subscribe' ? trial === '1' ? `${t('pages.payments.subscribe')} - ${t('common.travel_max_trial.title')}` : t('pages.payments.subscribe') : source === 'buy-trip' ? t('pages.payments.buy_trip_plans') : t('pages.payments.payment_method')}
      />
      <>
        {
          confirmPayment && <ConfirmPayment
            paymentIntentData={paymentIntentData}
            stripePromise={stripePromise}
            subscriptionConfirmed={subscriptionConfirmed}
            setServerError={setServerError}
            setLoading={setLoading}
          />
        }
        {
          source === 'subscribe' ?
            <SubscriptionForm
              processSubmit={processSubmit}
              savePaymentMethod={savePaymentMethod}
              stripePromise={stripePromise}
              stripeSetupFormRef={stripeSetupFormRef}
              errors={errors}
              loading={loading}
              subscribe={subscribe}
              processingPaypal={processingPaypal}
              checkoutWithPaypal={checkoutWithPaypal}
              subscriptionDuration={subscriptionDuration}
              setSubscriptionDuration={setSubscriptionDuration}
              requestValues={requestValues}
              serverError={serverError}
              trial={trial === '1'}
              register={register}
              promoValid={promoValid}
              promoCodeModel={promoCodeModel}
              promoMessage={promoMessage}
            />
            :
            <PaymentMethodForm
              processSubmit={processSubmit}
              savePaymentMethod={savePaymentMethod}
              stripePromise={stripePromise}
              stripeSetupFormRef={stripeSetupFormRef}
              errors={errors}
              loading={loading}
              source={source}
              buyTripPlan={buyTripPlan}
              serverError={serverError}
            />
        }
      </>
    </>
  )
}

export default Payments