import { Form } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from '@/services/Auth'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@/shared/components/LoadingButton'
import LOCATION from '@/constants/Location'
import AuthLayout from './AuthLayout'

import api from '../../services/Api'

function Login () {

  const { t } = useTranslation()
  const auth = useAuth()
  const history = useHistory()
  const { email } = useParams()
  const [loading, setLoading] = useState(false)
  const [serverError, setServerError] = useState('')
  const [serverErrors, setServerErrors] = useState([])
  const [requestValues, setRequestValues] = useState(null)

  const intended = localStorage.getItem('intended');

  let passwordMinLength = 6

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('common.form_validation.invalid_email_address'))
      .required(t('common.form_validation.email_address_is_required')),
    password: yup.string()
      .min(passwordMinLength, t('common.form_validation.password_invalid_min_length', { length: passwordMinLength }))
      .required(t('common.form_validation.password_is_required')),
  })

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const signIn = (values) => {
    setServerError('')
    setLoading(true)

    api.get('/csrf-cookie')
      .then(response => {
        api.post('/login', values)
          .then(response => {
            if (requestValues) {
              window.location.href = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/summary`
            } else if(intended) {
              localStorage.removeItem('intended');
              window.location.href = intended;
            } else {
              window.location.href = '/'
            }
            setLoading(false)
          })
          .catch(error => {
            setLoading(false)
            if (error?.response?.status === 401) {
              setServerError(error.response.data.message)
            }

            if (error.response.status === 422) {
              let serverErrors = []
              for (const key in error.response.data.errors) {
                serverErrors.push(
                  error.response.data.errors[key][0]
                )
              }
              setServerErrors(serverErrors)
            }
          })

      })
  }

  useEffect(() => {
    if (email) {
      setValue('email', email)
    }
  }, [])

  useEffect(() => {
    let requestData = localStorage.getItem('request_data')
    if (requestData) {
      setRequestValues(JSON.parse(requestData))
    }

  }, [])

  return (
    <AuthLayout
      title={t('pages.login.title')}
      subtitle={t('pages.login.welcome_message')}
    >
      <Form noValidate onSubmit={handleSubmit(signIn)}>
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Label className="heading-text">{t('common.form.email')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.email') })}
            {...register('email')}
            isInvalid={!!errors.email}
          />

          <Form.Control.Feedback type="invalid">
            {errors.email && errors.email.message}
          </Form.Control.Feedback>
        </Form.Group>
        {
          serverError !== '' &&
          <div className="form-group mb-2">
            <p className="text-danger py-1 mb-0">{t(serverError)}</p>
          </div>
        }
        {
          (serverErrors.length !== 0) &&
          <div className="form-group mb-2">
            {
              serverErrors.map((error, index) =>
                <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
            }
          </div>
        }

        <Form.Group className="mb-4" controlId="formBasicPassword">
          <Form.Label className="heading-text">{t('common.form.password')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('common.form.password')}
            {...register('password')}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password && errors.password.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="d-flex flex-lg-row flex-column justify-content-between">
          <Form.Check
            {...register('remember')}
            type="checkbox"
            id="remember"
            label={t('common.form.remember_me')}
          />
          <Link
            to={LOCATION.AUTH.FORGOT_PASSWORD.path}
            className="mx-0 px-0 mt-4 mt-lg-0"
          >
            {t('pages.login.forgot_password')}
          </Link>
        </Form.Group>
        <Form.Group className="d-grid gap-2 mt-5">
          <LoadingButton
            className="auth-btn text-uppercase fw-bold text-white heading-text"
            loading={loading}
            variant="primary"
            titleTranslationKey="pages.login.login_button_text"
            type="submit"
          />
        </Form.Group>
        <Form.Group className="d-flex flex-row mt-5">
          <Form.Label className="me-1">{t('pages.login.dont_have_an_account_sign_up')}</Form.Label>
          <Link
            to={LOCATION.AUTH.SIGN_UP.path}
            className="mx-0 px-0"
          >
            {t('pages.signup.link_title')}
          </Link>
        </Form.Group>
      </Form>
    </AuthLayout>
  )
}

export default Login