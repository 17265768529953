import trips from './pages/Trips'
import deals from './pages/Deals'
import profile from './pages/Profile'
import Dashboard from '../shared/Dashboard'
import users from './pages/Admin/Users'
import locations from './pages/Admin/Locations'
import holidays from './pages/Admin/Holidays'
import top_15_deals from './pages/Admin/Top15Deals'
import destination_specific_deals from './pages/Admin/DestinationSpecificDeals';
import travel_resources from './pages/TravelResources'
import payments from './pages/Payments'
import notifications from './pages/Notifications'
import stats from './pages/Admin/Stats'
import requests from './pages/Admin/TripRequests'
import subscription_management from './pages/Admin/SubscriptionManagement'

const Private = [
  {
    path: '/',
    component: Dashboard,
    exact: true,
    translation_key: 'common.dashboard',
    main: false,
  },
  ...stats,
  ...trips,
  ...deals,
  ...profile,
  ...users,
  ...locations,
  ...holidays,
  ...top_15_deals,
  ...destination_specific_deals,
  ...travel_resources,
  ...payments,
  ...notifications,
  ...requests,
  ...subscription_management,
]
export default Private
