import LOCATION from "../../../constants/Location";
import SubscriptionManagement from "../../../pages/Admin/SubscriptionManagement";
import Create from "../../../pages/Admin/SubscriptionManagement/Promotions/Create";

const Requests = [
  {
    path: LOCATION.SUBSCRIPTION_MANAGEMENT.LIST.path,
    component: SubscriptionManagement,
    exact: true,
    main: false,
    gate: 'subscription-management',
  },
  {
    path: LOCATION.SUBSCRIPTION_PRODUCT_PROMOTIONS.CREATE.path,
    component: Create,
    exact: true,
    main: false,
    gate: 'subscription-management',
  }
];

export default Requests;