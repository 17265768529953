const common = {
  id: 'ID',
  app: 'The Travel Mum',
  privacy_policy: 'Privacy',
  go_home: 'Go Home',
  submit: 'Submit',
  logout: 'Log Out',
  save: 'Save',
  retry: 'Retry',
  user: 'User',
  print: 'Print',
  download: 'Download',
  terms_and_condition: 'T&Cs',
  contact_us: 'Contact',
  please_wait: 'Please wait...',
  feature_image_size: 'Required Size {{size}}',
  choose: 'Choose',
  all: 'All',
  choose_number_of_days: 'Choose number of days ',
  choices: 'Choices',
  scores: 'Scores',
  correct_answer: 'Correct Answer',
  ratings: 'Ratings',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  add: 'Add {{attribute}}',
  add_other: 'Add Other {{attribute}}',
  remove: 'Remove {{attribute}}',
  search: 'Search',
  assessment: 'Assessment',
  status: 'Status',
  assessment_date_between: 'Assessment Date Between',
  search_for_anything: 'Search For Anything',
  search_for_a_locations: 'Search For a Location',
  search_for_a_filters: 'Search For a Filter',
  apply: 'Apply',
  update: 'Update',
  loading: 'Loading...',
  no_data: 'No Data',
  score: 'Score',
  branch: 'Branch',
  okay: 'Okay',
  or: 'Or',
  none: 'None',
  total_price: 'Total Price: {{currency}}{{price}}',
  total_price_heading: 'Total Price',
  total_price_from: 'Total Price From: {{currency}}{{price}}',
  total_price_from_short: 'From: {{currency}}{{price}}',
  card: 'Card',
  expires: 'Expires',
  no_payment_method_stored: 'No Payment Method',
  no_active_subscription: 'No Active Subscription',
  subscription: 'Subscription',
  valid_until: 'Valid Until',
  trial_valid_until: 'Trial Valid Until',
  cost: 'Cost',
  start_date: 'Start Date',
  dates: 'Dates',
  airport: 'Airport',
  hotel: 'Hotel',
  flights: 'Flights',
  hotels: 'Hotels',
  week: 'Week',
  duration: 'Duration',
  duration_in_days: '{{numberOfDays}} days',
  date_created: 'Date Created',
  deal_type: 'Deal Type',
  complete: 'Completed',
  incomplete: 'Incomplete',
  limit_reached: 'Call Limit',
  search_for_airports: 'Search For Airports',
  yes: 'Yes',
  no: 'No',
  form: {
    placeholder_enter: 'Enter {{attribute}}',
    placeholder_select: 'Select {{attribute}}',
    password: 'Password',
    email: 'Email Address',
    verification_code: 'Verification Code',
    first_name: 'First Name',
    last_name: 'Last Name',
    remember_me: 'Remember Me',
    re_enter_password: 'Re-enter Password',
    phone_number: 'Phone Number',
    description: 'Description',
    description_placeholder: 'Enter Description',
    featured_image: 'Upload Featured Image',
    thumbnail: 'Upload Thumbnail Image',
    required: 'Required',
    your_message: 'Your Message',
    message: 'Message',
    type_here: 'Type Here...',
    name: 'Name',
    mobile_number: 'Mobile Number',
    website: 'Website',
    preferred_airport: 'Preferred Airport(s)',
    city: 'City',
    country: 'Country',
    holiday_types: 'Holiday Types',
    locations: 'Locations',
    confirmation_code: 'Confirmation code',
    custom_message: 'Custom Message',
  },
  form_validation: {
    is_required: '{{attribute}} is required',
    must_be_a_number: '{{attribute}} must be number',
    first_name_is_required: 'First name is required',
    last_name_is_required: 'Last name is required',
    invalid_email_address: 'Please use valid email address',
    password_invalid_min_length: 'Password must not be less than {{length}} characters',
    password_is_must_match: 'Password must match',
    password_confirm_required: 'Password confirm required',
    title_is_required: 'Title is required',
    max: '{{field}} value should not be more than {{max}}',
    invalid_ccv: 'Invalid CCV',
    invalid_expire: 'Invalid Expire Date',
    invalid_card_number: 'Invalid Card Number',
    email_address_is_required: 'Email Address is required',
  },
  buttons: {
    back: 'Back',
    continue: 'Continue',
    load_more: 'Load More',
    save: 'Save',
    update: 'Update',
    cancel: 'Cancel',
    back_to_members_area: 'Back to members area',
    remove: 'Remove',
    preserve: 'Preserve',
    unpreserve: 'Unpreserve',
    remove_hotel: 'Remove Hotel',
    delete: 'Delete',
    edit: 'Edit',
    add_payment_method: 'Add Payment Method',
    subscribe: 'Subscribe',
    try_subscription: 'or Try for 14 days',
    try_travel_max_subscription: 'or Try Travel Max for 14 days',
    go_home: 'Go Home',
    dismiss: 'Dismiss',
    dismiss_all: 'Dismiss All',
    submit: 'Submit',
  },
  formats: {
    month_year: 'MMM YY',
    date: 'Do MMM YY',
    short_date: 'DD/MM/YYYY',
    short_year_date: 'DD/MM/YY',
    short_time: 'HH:mm',
    datetime: 'Do MMM YY HH:mm',
    short_datetime: 'DD/MM/YY HH:mm',
    long_date: 'dddd Do MMMM YYYY',
    date_with_day: 'ddd DD MMM YY',
  },
  status_list: {
    not_started: 'Not Started',
    completed: 'Completed',
    in_progress: 'In Progress',
    audit_requested: 'Audit Requested',
  },
  errors: {
    recaptcha_error: 'ReCAPTCHA Error!',
    invalid_credentials: 'Invalid Credentials',
    invalid_verification_code_or_email: 'Invalid Verification Code or Email',
    verification_code_is_expired: 'Verification Code is invalid or expired',
    invalid_token: 'Invalid Token!',
    we_dont_recognize_your_email: 'Sorry we don\'t recognize this email',
    titles: {
      error500: '500 | SERVER ERROR',
      error404: '404 | PAGE NOT FOUND',
      error403: '403 | UNAUTHORIZED REQUEST',
      connection_error: 'No internet',
    },
    body: {
      error500: 'Sorry, this page could not be loaded',
      error404: 'Sorry, the page you are looking for does not exist, have been removed, name changed or is temporary unavailable',
      error403: 'Sorry, you are not authorized to access the page you requested',
      connection_error: 'We were not able to establish a working connection, please make sure your device is connection to the internet then retry',
    }
  },
  dialogs: {
    titles: {
      are_you_sure: 'Are you sure?',
      update_success: 'Updated Successful!',
    },
    body: {},
    actions: {
      okay: 'Okay',
      remove: 'Remove',
      delete: 'Delete',
      cancel: 'Cancel',
      no: 'No',
      yes: 'Yes',
      take_me_there: 'Take me there',
      edit_my_search: 'Edit my Search',
    }
  },
  subscriptions: {
    types: {
      travel_max: 'Travel Max',
      travel_lite: 'Travel Lite',
      travel_max_trial: 'Travel Max Trial',
    }
  },
  notifications: {
    subscribed: {
      title: 'Subscribed',
      body: 'You have successfully subscribed to our Travel Max plan! Enjoy your subscription',
    },
    unsubscribed_to_deals_email: {
      title: 'Unsubscribed',
      body: 'You have unsubscribed from receiving deals on your email. You can always subscribe back through your preferences page. ',
    },
    skip_login_sign_up_on_search: {
      title: 'You haven’t signed up yet',
      body: 'You need to sign up for a free account to see and save your search results',
    },
    dismiss_all_notification: {
      title: 'Dismiss all notifications?',
    },
    dismiss_this_notification: {
      title: 'Dismiss this notification?',
    }
  },
  travel_max_trial: {
    title: '14 DAY FREE TRIAL!',
    subtitle: 'Unlock Affordable Travel with The Travel Mum',
    trial_statement: 'For a limited time only you can trial our premium Travel Max plan for free! You can cancel at any time within this period but we are pretty sure you will love it!',
    features: {
      gain_exclusive_entry: 'Gain exclusive entry to the coveted Travel Max members area, where you\'ll bask in a world of personalised deals tailored just for you!',
      take_control: 'Take control of your travel destiny like never before. Set your budget, specify preferred airports, choose your ideal group size, and define your availability to unveil a plethora of jaw-dropping, custom deals right in your inbox!',
      limitless_potential: 'Unleash the limitless potential of your wanderlust with our unparalleled expert assistance and bespoke advice for any travel query.',
      multiple_adventures: 'Seek out multiple thrilling adventures and hoard them in your "saved trips", allowing you to compare prices and come back to them later.',
      passionate_community: 'Join our passionate community of adventure lovers and unlock an exhilarating new way to travel!',
    }
  },
  travel_max_feature: {
    title: 'Travel Max',
    travel_mum_feature_heading: 'sorry, this is a travel max feature.',
    premium: 'Premium',
    price_title: 'Let us do the hard work for you for just {{currency}}{{price}} for the whole year!',
    price_title_with_month: 'Just {{currency}}{{yearPrice}} for the year (or {{currency}}{{quarterPrice}} every 3 months)',
    heading_statement: 'Unlock Customised Affordable Travel with The Travel Mum for Just £30 for the Year',
    price_statement: 'Unlock a world of effortless and affordable travel with our unbeatable combination of personalised deals and expert advice! Say goodbye to endless searches and hello to boundless adventures that will save you both time and money!',
    gain_access_statement: 'Gain access to unlimited travel bargains and support. Booking flights and hotels separately can be stressful, but we are here to make it easy',
    buttons: {
      continue_to_your_deal: 'continue to your deal',
      sign_up_travel_max: 'Sign up Travel Max',
      sing_up_now: 'Sign up now',
      maybe_later: 'Maybe Later',
      login: 'Log in',
    },
    we_make_budget_travel_easy: 'We make budget travel easy',
    features: {
      access_to_members_area: 'Access to Travel Max Members Area',
      request_customized_trips: 'Request Customised Trips from our Experts',
      help_with_everything: 'Help with everything from Transfers to Activities',
      assistance_24_7: '24/7 Assistance with your Travel Queries',
      set_preferences: 'Set your Preferred Airports, Holiday Style and Budget for Personalised Deals Displayed in your Customised Members Area',
      download_resources: 'Downloadable Resources (eg packing lists / travel planners / destination guides)',
      downloadable_resources: 'Downloadable resources.',
      see_results_if_deal_search: 'See the results of Deal Searches',
      save_deals: 'Save Deals for Later',
      deals_sent_to_inbox: 'Deals Sent Straight to Your Inbox',
      view_to_15_deals: 'View our Top 15 Deals of the week',
      need_inspiration: 'Need some Inspiration? Browse deals curated just for you',
      expert_travel_insurance: 'Expert Travel Insurance advice to ensure your trip is well protected',

      set_airport_preferences: 'Set airport preferences to see custom deals.',
      curated_deals: 'Browse deals curated for you.',
      save_trips: 'Save multiple trips to compare prices.',
      multiple_trips: 'Save multiple trips to compare prices.',
      join_exclusive_community: 'Join our exclusive facebook community and share tips.',
      exclusive_deals: 'Exclusive deals.',
      giveaways: 'Giveaways.',
      and_more: 'And much more!.',

      see_the_results_of_deal_searches: 'See the results of Deal Searches',
      set_preferences_to_receive_tailored_deals_without_the_need_to_search: 'Set Preferences to receive tailored deals without the need to search',
      browse_4_and_5_deals_from_your_local_airport: 'Browse 4* and 5* deals from your local airport',
      browse_weekend_trips_from_your_local_airport: 'Browse Weekend trips from your local airport',
      browse_trips_under_200_from_your_local_airport: 'Browse trips under £200 from your local airport',
      our_personal_help_with_any_travel_query: 'Our personal help with any travel query',

      set_airport_preferences_to_see_custom_deals: 'Set airport preferences to see custom deals.',
      browse_deals_curated_for_you: 'Browse deals curated for you.',
      save_multiple_trips_to_compare_prices: 'Save multiple trips to compare prices.',
    }
  }
}
export default common
